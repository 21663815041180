import { createMuiTheme } from '@material-ui/core/styles'
import { ruRU } from '@material-ui/core/locale'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'

const theme = createMuiTheme({
	zIndex: {
		appBar: 1200,
		drawer: 1100
	},
	palette,
	overrides,
	typography
}, ruRU)

export default theme
