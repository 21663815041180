export const getId = (data: any, name: string) => {
    if (!data[name]) {
        return null
    }

    if (typeof data[name] === 'number') {
        return
    }
    const { id } = data[name]
    return data[name] = id
}