export const convertToArray = (data: any, elem = '') => {
    if (!data) return

    if (`${elem}` in data) {
        let value = data[`${elem}`]

        if (Array.isArray(value)) {
            return
        }

        if (typeof value === 'string') {
            return data[`${elem}`] = Array(value)
        } else if (typeof value === 'number') {
            return data[`${elem}`] = [value]
        }
    }
}