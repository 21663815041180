import { defaultList } from 'share/store/defaultList'
class bannerStore extends defaultList {

	name = 'bannerStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.banners.index',
			show: 'api.admin.banners.show',
			update: 'api.admin.banners.update',
			store: 'api.admin.banners.store',
			destroy: 'api.admin.banners.destroy'
		}
	}
}

export default new bannerStore()
