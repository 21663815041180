import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Loader } from 'share/components'
import { getRoutes as routes, useStore } from 'share/helper'
import { NotFound, Private } from './components'

const RoutesComponent: React.FC = () => {

	const { authStore } = useStore()

	const pass = authStore.pass

	return (
		<Suspense fallback={<Loader />}>
			<Switch>
				{routes.map((route: any) => {
					return !pass && !route.public
						? <Redirect key={route.id} {...route} to="/login" />
						: pass && route.public
							? <Redirect key={route.id} {...route} to="/" />
							: <Private key={route.id} {...route} />
				})}
				<Route component={NotFound} />
			</Switch>
		</Suspense>
	)
}


export const Routes = observer(RoutesComponent)
