import { defaultList } from 'share/store/defaultList'

class partStore extends defaultList {

	name = 'partStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.parts.index',
			show: 'api.admin.parts.show',
			update: 'api.admin.parts.update',
			store: 'api.admin.parts.store',
			destroy: 'api.admin.parts.destroy'
		}
	}
}

export default new partStore()
