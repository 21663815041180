import React from 'react'
import EventIcon from '@material-ui/icons/Event'

export default [
	{
		id: 'seminar',
		path: '/seminar/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'seminar.list',
		path: '/seminar',
		sort: 180,
		exact: true,
		menu: true,
		section: 'content',
		icon: () =>  <EventIcon />,
		name: 'Семинары',
		redirect: true,
		to: '/seminar/1',
	},
	{
		id: 'seminar.store',
		path: '/seminar-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'seminar.show',
		path: '/seminar-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
