import React from 'react'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'

export default [
	{
		id: 'feature',
		path: '/feature/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'feature.list',
		path: '/feature',
		sort: 125,
		exact: true,
		menu: true,
		section: 'catalog',
		icon: () => <SettingsInputComponentIcon />,
		name: 'Характеристики',
		redirect: true,
		to: '/feature/1',
	},
	{
		id: 'feature.store',
		path: '/feature-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'feature.show',
		path: '/feature-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
