import React from 'react'
import PaymentIcon from '@material-ui/icons/Payment'

export default [
	{
		id: 'promotion',
		path: '/promotion/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'promotion.list',
		path: '/promotion',
		sort: 120,
		exact: true,
		menu: true,
		section: 'content',
		icon: () =>  <PaymentIcon />,
		name: 'Акции',
		redirect: true,
		to: '/promotion/1',
	},
	{
		id: 'promotion.store',
		path: '/promotion-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'promotion.show',
		path: '/promotion-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
