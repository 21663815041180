import { defaultList } from 'share/store/defaultList'
class articleStore extends defaultList {

	name = 'articleStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.articles.index',
			show: 'api.admin.articles.show',
			update: 'api.admin.articles.update',
			store: 'api.admin.articles.store',
			destroy: 'api.admin.articles.destroy'
		}
	}
}

export default new articleStore()
