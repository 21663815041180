import { observable, action } from 'mobx'
import axios from 'axios'
import { getRouteName } from 'share/helper'

export class defaultList {

	public route_list: any = {
		index: '',
		show: '',
		update: '',
		store: '',
		destroy: ''
	}

	public id = 'id'

	@observable list: any = {}

	@observable item: any = null

	@observable form = {
		errors: null
	}

	@action clearItem = () => {
		this.item = null
	}

	/**
	 * Get list items
	 *
	 * @param data get params
	 */

	@action index = (data: any) => {
		axios.get(getRouteName(this.route_list.index), { params: data })
			.then(({ data }) => {
				this.list = data
			})
			.catch(error => console.error('error', error))
	}

	@action indexWithId = (id: number) => {
		axios.get(`api/admin/pages/${id}/parts`)
			.then(({ data }) => {
				this.list = data
			})
			.catch(error => console.error('error', error))
	}

	/**
	 * Get item
	 *
	 * @param id
	 */
	@action show = (id: number) => {
		//figure out how to draw detailed pages
		// remove this part
		this.clearItem()
		axios.get(getRouteName(this.route_list.show, id))
			.then(({ data }) => {
				this.item = data.data
			})
			.catch(error => console.error('error', error))
	}

	@action update = (id: number, form: any, success: () => void) => {
		axios.post(getRouteName(this.route_list.update, id), form)
			.then(({ data }) => {
				this.form.errors = null
				success()
			})
			.catch(({ response }) => {
				this.form.errors = response?.data?.errors ?? null
			})
	}

	@action store = (form: any, success: () => void) => {
		axios.post(getRouteName(this.route_list.store), form)
			.then(() => {
				this.form.errors = null
				success()
			})
			.catch(({ response }) => {
				this.form.errors = response?.data?.errors ?? null
			})
	}

	@action destroy = (id: number) => {
		//replace with a notification material-ui
		confirm('Вы действительно хотите удалить элемент?') ? // eslint-disable-line
			axios.delete(getRouteName(this.route_list.destroy, id))
				.then(() => {
					this.list.data = this.list.data.filter((item: any) => item[this.id] !== id)
				})
				.catch(error => console.error('error', error)) : false
	}
}
