import React from 'react'
import { NavLink } from 'react-router-dom'

/**
 * Page not found (404)
 *
 * @returns {*}
 * @constructor
 */


export const NotFound: React.FC = () => {
	return (
		<div>
			<h3>404</h3>
			<NavLink to="/">
				← На главную
			</NavLink>
		</div>
	)
}
