import React from 'react'
import EventNoteIcon from '@material-ui/icons/EventNote'

export default [
	{
		id: 'article',
		path: '/article/:page',
		component: React.lazy(() => import('./page'))
	},
	{
		id: 'article.list',
		path: '/article',
		sort: 126,
		exact: true,
		menu: true,
		section: 'content',
		icon: () => <EventNoteIcon />,
		name: 'Статьи',
		redirect: true,
		to: '/article/1'
	},
	{
		id: 'article.store',
		path: '/article-store',
		component: React.lazy(() => import('./page/store'))
	},
	{
		id: 'article.show',
		path: '/article-show/:id',
		component: React.lazy(() => import('./page/show'))
	}
] as TRoute[]
