import React from 'react'
import WebIcon from '@material-ui/icons/Web'

export default [
	{
		id: 'page',
		path: '/page/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'page.list',
		path: '/page',
		sort: 129,
		exact: true,
		menu: true,
		section: 'content',
		icon: () => <WebIcon />,
		name: 'Страницы',
		redirect: true,
		to: '/page/1',
	},
	{
		id: 'page.store',
		path: '/page-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'page.show',
		path: '/page-show/:id',
		component: React.lazy(() => import('./page/show')),
	},

] as TRoute[]
