import React from 'react'
import SubjectIcon from '@material-ui/icons/Subject'

export default [
	{
		id: 'section',
		path: '/section/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'section.list',
		path: '/section',
		sort: 120,
		exact: true,
		menu: true,
		section: 'content',
		icon: () => <SubjectIcon />,
		name: 'Разделы сайта',
		redirect: true,
		to: '/section/1',
	},
	{
		id: 'section.store',
		path: '/section-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'section.show',
		path: '/section-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
