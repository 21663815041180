/**
 * Format date dd.mm.YYYY
 *
 * @param iso
 * @returns {string}
 */
export const dateSplit = (iso: string) => {

	if (!iso) {
		return ''
	}

	const date = new Date(iso)
	const dd = date.getDate()
	const mm = date.getMonth() + 1

	return `${date.getFullYear()}-${mm < 10 ? '0' + mm : mm}-${dd < 10 ? '0' + dd : dd}`
}
