import React from 'react'
import Image from '@material-ui/icons/Image'

export default [
	{
		id: 'banner',
		path: '/banner/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'banner.list',
		path: '/banner',
		sort: 124,
		exact: true,
		menu: true,
		section: 'content',
		icon: () =>  <Image />,
		name: 'Баннеры',
		redirect: true,
		to: '/banner/1',
	},
	{
		id: 'banner.store',
		path: '/banner-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'banner.show',
		path: '/banner-show/:id',
		component: React.lazy(() => import('./page/show')),
	},
	{
		id: 'banner.images.show',
		path: '/banner-images-show/:id',
		component: React.lazy(() => import('./page/show-images')),
	},
] as TRoute[]
