import React from 'react'
import Description from '@material-ui/icons/Description'

export default [
	{
		id: 'news',
		path: '/news/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'news.list',
		path: '/news',
		sort: 125,
		exact: true,
		menu: true,
		section: 'content',
		icon: () =>  <Description />,
		name: 'Новости',
		redirect: true,
		to: '/news/1',
	},
	{
		id: 'news.store',
		path: '/news-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'news.show',
		path: '/news-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
