import React from 'react'
import DriveEta from '@material-ui/icons/DriveEta'

export default [
	{
		id: 'brand',
		path: '/brand/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'brand.list',
		path: '/brand',
		sort: 130,
		exact: true,
		menu: true,
		section: 'ref',
		icon: () => <DriveEta />,
		name: 'Бренды',
		redirect: true,
		to: '/brand/1',
	},
	{
		id: 'brand.store',
		path: '/brand-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'brand.show',
		path: '/brand-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
