import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
	Drawer, List, ListItem,
	Divider, Typography, Collapse
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { getRoutes as routes, useStore } from 'share/helper'
import { useStyles } from './useStyles'


const SidebarComponent: React.FC = () => {

	const classes = useStyles()
	const { authStore } = useStore()

	const category = [
		{ id: 'catalog', title: 'Каталог' },
		{ id: 'content', title: 'Контент' },
		{ id: 'ref', title: 'Справочники' },
		{ id: 'settings', title: 'Настройки' },
		{ id: 'other', title: 'Прочее' }
	]

	const [open, setOpen] = useState<any>({
		catalog: false,
		content: false,
		ref: false
	})

	const menu: any = category.reduce((p, c) => ({
		...p,
		[c.id]: routes.filter(item => item.menu && item.section === c.id)
	}), {})


	return (
		<Drawer
			anchor="left"
			open={true}
			classes={{ paper: classes.drawer }}
			variant='persistent'
		>
			<div className={classes.user}>
				<Typography className={classes.name} variant="h4">
					{authStore?.user?.full_name ?? ''}
				</Typography>
				<Typography variant="body2">
					{authStore?.user?.role?.name ?? ''}
				</Typography>
			</div>

			<Divider className={classes.divider} />

			<List>
				{category.map(item => <div key={item.id}>
					<ListItem className={classes.row}>
						<div className={classes.link} onClick={() => setOpen({ ...open, [item.id]: !open[item.id] })}>
							<span className={classes.text}>{item.title}</span>
							{open[item.id] ? <ExpandLess /> : <ExpandMore />}
						</div>

						<Collapse
							in={open[item.id]}
							timeout="auto"
							unmountOnExit
							className={classes.collapse}
						>
							<List disablePadding>
								{(menu[item.id] ?? []).map((section: TRoute) => section.menu ?
									<ListItem key={section.id} className={classes.item}>
										<NavLink
											exact={section.exact}
											to={section.path}
											className={classes.link}
										>
											<span className={classes.icon}>{section.icon && section.icon()}</span>
											<span className={classes.text}>{section.name}</span>
										</NavLink>
									</ListItem> : null)}
							</List>
						</Collapse>

					</ListItem>
				</div>)}
			</List>
		</Drawer>
	)
}

export const Sidebar = observer(SidebarComponent)
