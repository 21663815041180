import React from 'react'
import LocationCityIcon from '@material-ui/icons/LocationCity'

export default [
	{
		id: 'city',
		path: '/city/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'city.list',
		path: '/city',
		sort: 130,
		exact: true,
		menu: true,
		section: 'ref',
		icon: () => <LocationCityIcon />,
		name: 'Города',
		redirect: true,
		to: '/city/1',
	},
	{
		id: 'city.store',
		path: '/city-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'city.show',
		path: '/city-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
