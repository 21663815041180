import React from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AppBar, Toolbar, IconButton, Hidden } from '@material-ui/core'
import { Menu as MenuIcon, Input as InputIcon } from '@material-ui/icons'
import { useStore } from 'share/helper'
import { useStyles } from './useStyles'


const HeaderComponent: React.FC = () => {

	const classes = useStyles()
	const { authStore } = useStore()

	return (
		<AppBar className={classes.root}>
			<Toolbar>
				<div>
					<NavLink to="/" className={classes.logo}>Autoeuro</NavLink>
				</div>
				<div className={classes.flexGrow} />

				<Hidden lgUp>
					<IconButton color="inherit">
						<MenuIcon />
					</IconButton>
				</Hidden>

				<IconButton
					color="inherit"
					className={classes.logOut}
					onClick={authStore.logout}
				>
					<InputIcon />
				</IconButton>

			</Toolbar>
		</AppBar>
	)
}

export const Header = observer(HeaderComponent)
