import React from 'react'
import { useStyles } from './useStyles'

export const Content: React.FC = ({ children }) => {

	const classes = useStyles()

	return (
		<main className={classes.content}>
			{children}
		</main>
	)
}
