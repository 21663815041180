import React from 'react'
import CategoryIcon from '@material-ui/icons/Category'

export default [
	{
		id: 'category',
		path: '/category/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'category.list',
		path: '/category',
		sort: 120,
		exact: true,
		menu: true,
		section: 'catalog',
		icon: () => <CategoryIcon />,
		name: 'Категории',
		redirect: true,
		to: '/category/1',
	},
	{
		id: 'category.store',
		path: '/category-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'category.show',
		path: '/category-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
