export const generateFeature = (data: any, features: any) => {
    let feature = {}

    for (let i = 0; i < features.length; i++) {

        if (`featuresCategory${i}` in data) {
            let name

            if (typeof data[`featuresCategory${i}`] === 'object') {
                name = data[`featuresCategory${i}`].code
            } else {
                name = [data[`featuresCategory${i}`]]
            }

            feature = {
                ...feature,
                [name]: data[`featureValues${i}`]
            }

            delete data[`featuresCategory${i}`]
            delete data[`featureValues${i}`]
        }
    }

    data.features = feature
    return data
}