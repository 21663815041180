export const getFormData = (object: any) => Object.keys(object).reduce((formData, key) => {

	let item = object[key]

	if (Array.isArray(item)) {
		for (let i = 0; i < item.length; i++) {
			formData.append(`${key}[]`, item[i])
		}
	} else {
		formData.append(key, item)
	}

	return formData
}, new FormData())
