import React from 'react'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'

export default [
	{
		id: 'product',
		path: '/product/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'product.list',
		path: '/product',
		sort: 120,
		exact: true,
		menu: true,
		section: 'catalog',
		icon: () => <ShoppingBasketIcon />,
		name: 'Товары',
		redirect: true,
		to: '/product/1',
	},
	{
		id: 'product.store',
		path: '/product-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'product.show',
		path: '/product-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]