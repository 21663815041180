import React from 'react'
import CodeIcon from '@material-ui/icons/Code'

export default [
	{
		id: 'script',
		path: '/script/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'script.list',
		path: '/script',
		sort: 120,
		exact: true,
		menu: true,
		section: 'settings',
		icon: () => <CodeIcon />,
		name: 'Скрипты',
		redirect: true,
		to: '/script/1',
	},
	{
		id: 'script.store',
		path: '/script-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'script.show',
		path: '/script-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
