import { useState, useEffect } from 'react'
import axios from 'axios'
import { getRouteName } from './getRouteName'


export const useGetOptions = (route: any) => {
	const [list, setList] = useState<any[]>([])

	useEffect(() => {
		axios.get(getRouteName(route))
			.then(({ data }: any) => {
				setList(data.data ?? [])
			})
	}, [route]) //eslint-disable-line

	return list
}
