import { defaultList } from 'share/store/defaultList'
class sectionStore extends defaultList {

	name = 'sectionStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.sections.index',
			show: 'api.admin.sections.show',
			update: 'api.admin.sections.update',
			store: 'api.admin.sections.store',
			destroy: 'api.admin.sections.destroy'
		}
	}
}

export default new sectionStore()
