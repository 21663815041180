import React from 'react'
import { observer } from 'mobx-react'
import { useStore } from 'share/helper'
import { useStyles } from './useStyles'


const RowComponent: React.FC = ({ children }) => {

	const { authStore } = useStore()
	const classes = useStyles(authStore.pass)()

	return (
		<div className={classes.root}>
			{children}
		</div>
	)
}

export const Row = observer(RowComponent)
