import { defaultList } from 'share/store/defaultList'
class bannerImagesStore extends defaultList {

	name = 'bannerImagesStore'

	constructor() {
		super()
		this.route_list = {
			show: 'api.admin.banners.image.show',
			update: 'api.admin.banners.image.update',
			store: 'api.admin.file.image.upload',
			destroy: 'api.admin.banners.image.destroy'
		}
	}
}

export default new bannerImagesStore()
