import { defaultList } from 'share/store/defaultList'
class productStore extends defaultList {

	name = 'productStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.product.index',
			show: 'api.admin.product.show',
			update: 'api.admin.product.update',
			store: 'api.admin.product.store',
			destroy: 'api.admin.product.destroy'
		}
	}
}

export default new productStore()
