import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const useGetShow = (store: any) => {

	const { id } = useParams()

	useEffect(() => {
		store.show(id)
	}, []) //eslint-disable-line

	return [id, store?.item ?? null]
}
