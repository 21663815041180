import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme: any) => ({
	drawer: {
		width: 240,
		marginTop: 64,
		height: 'calc(100% - 64px)',
	},
	link: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		textDecoration: 'none',
		color: theme.palette.text.primary,
		fontWeight: theme.typography.fontWeightBold,
		alignItems: 'center',
		padding: '10px 8px',
		cursor: 'pointer',
		'&.active': {
			color: theme.palette.primary.main
		},
		'&:hover': {
			backgroundColor: 'rgba(38, 50, 56, 0.08)'
		}
	},
	item: {
		paddingTop: 0,
	},
	row: {
		paddingTop: 0,
		flexDirection: 'column',
		display: 'flex'
	},
	icon: {
		color: theme.palette.icon,
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	text: {
		display: 'block'
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	user: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: 'fit-content'
	},
	name: {
		marginTop: theme.spacing(1)
	},
	collapse: {
		width: '100%'
	}
}))
