import React from 'react'
import ContactsIcon from '@material-ui/icons/Contacts'


export default [
	{
		id: 'contact',
		path: '/contact/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'contact.list',
		path: '/contact',
		sort: 130,
		exact: true,
		menu: true,
		section: 'ref',
		icon: () => <ContactsIcon />,
		name: 'Контакты',
		redirect: true,
		to: '/contact/1',
	},
	{
		id: 'contact.store',
		path: '/contact-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'contact.show',
		path: '/contact-show/:id',
		component: React.lazy(() => import('./page/show')),
	},
] as TRoute[]
