import React from 'react'
import DashboardIcon from '@material-ui/icons/Dashboard'

export default [
    {
        id: 'home',
        path: '/',
        exact: true,
		sort: 100,
        menu: true,
		icon: () =>  <DashboardIcon />,
        name: 'Главная',
        component: React.lazy(() => import('./page')),
    }
] as TRoute[]
