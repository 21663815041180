import React from 'react'
import { Search } from 'share/components'
import { useStyles } from './useStyles'

type Props = {
	search?: boolean
}

export const ToolBar: React.FC<Props> = ({ search = false, children }) => {

	const classes = useStyles()

	return (
		<div>
			<div className={classes.row}>
				{children}
			</div>
			{search && <div className={classes.row}>
				<Search placeholder="Поиск" />
			</div>}
		</div>
	)
}
