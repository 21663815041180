import { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'


export const useGetListPage = (action: (data: any) => void) => {

	//pagination
	const { page } = useParams()
	//search
	const location = useLocation()

	useEffect(() => {
		action({ page, ...Object.fromEntries(new URLSearchParams(location.search)) })
	}, [page, location.search]) //eslint-disable-line
}
