import React from 'react'
import PeopleIcon from '@material-ui/icons/People'

export default [
	{
		id: 'user',
		path: '/user/:page',
		component: React.lazy(() => import('./page')),
	},
    {
        id: 'user.list',
        path: '/user',
        exact: true,
		sort: 110,
        menu: true,
		section: 'ref',
		icon: () =>  <PeopleIcon />,
        name: 'Пользователи',
		redirect: true,
		to: '/user/1',
    },
	{
		id: 'user.store',
		path: '/user-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'user.show',
		path: '/user-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
