import React from 'react'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

export default [
	{
		id: 'file',
		path: '/file/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'file.list',
		path: '/file',
		sort: 125,
		exact: true,
		menu: true,
		section: 'other',
		icon: () => <NoteAddIcon />,
		name: 'Файлы',
		redirect: true,
		to: '/file/1',
	},
	{
		id: 'file.store',
		path: '/file-store',
		component: React.lazy(() => import('./page/store')),
	},
] as TRoute[]
