import React from 'react'

export default [
    {
        id: 'login',
        path: '/login',
        exact: true,
        public: true,
        name: 'Авторизация',
        menu: false,
        component: React.lazy(() => import('./page/login')),
    },
    {
        id: 'reset',
        path: '/password/reset',
        exact: true,
        public: true,
        name: 'Востановление пароля',
        menu: false,
        component: React.lazy(() => import('./page/reset')),
    },
] as TRoute[]
