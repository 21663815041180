import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Input, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './useStyles'

type Prop = {
	placeholder?: string
}

export const Search: React.FC<Prop> = ({ placeholder = 'Поиск' }) => {

	const classes = useStyles()

	const [query, setQuery] = useState(new URLSearchParams(useLocation().search).get('q') || '')
	const history = useHistory()

	const handleChange = (value: string) => {
		setQuery(value)
	}

	const handleSubmit = (e: any) => {
		e.preventDefault()
		history.push(history.location.pathname + '?q=' + encodeURIComponent(query))
	}

	return (
		<Paper className={classes.row}>
			<form onSubmit={handleSubmit} className={classes.form}>
				<SearchIcon className={classes.icon} />
				<Input className={classes.input}
					value={query}
					disableUnderline
					placeholder={placeholder}
					onChange={e => handleChange(e.target.value)} />
			</form>
		</Paper>
	)
}
