import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from 'share/helper'
import { NotFound } from '../NotFound'

type Prop = {
	role: any[];
	redirect: boolean;
	to: string;
}
/**
 * Check role user
 */


const PrivateComponent: React.FC<Prop> = ({ redirect = false, role = [], ...props }) => {

	const { authStore } = useStore()
	const roleCurrent: string = authStore.user?.role?.code ?? ''

	return !(role.length > 0 && !role.includes(roleCurrent))
		? redirect
			? <Redirect {...props} />
			: <Route {...props} />
		: <Route component={NotFound} />
}

export const Private = observer(PrivateComponent)
