import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import * as serviceWorker from './serviceWorker'
import 'assets/scss/index.scss'
import { Layout } from 'core/components'
import store from 'core/store'

const App: React.FC = () => {
	return (
		<Provider {...store}>
			<Layout />
		</Provider>
	)
}

ReactDOM.render(
	<App />,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
