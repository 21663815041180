import React from 'react'

/**
 * Loader for component pages
 *
 * @returns {*}
 * @constructor
 */
export const Loader = () => {
    return <div className="load" />
}
