export const replaceValToArray = (data: any, string: string, replaceString: string) => {
    let array: number[] = []

    if (!data[string]) {
        data[replaceString] = array
        return data
    } else if (data[string].length === 0) {
        data[replaceString] = array
        return data
    } else if (data[string].length > 0) {
        data[string].map((item: any) => array.push(item.id))
        data[replaceString] = array
    }

    delete data[string]
    return data
}