import { defaultList } from 'share/store/defaultList'
import { action } from 'mobx'
import axios from 'axios'
import { getRouteName } from 'share/helper'
class seminarStore extends defaultList {

	name = 'seminarStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.seminars.index',
			show: 'api.admin.seminars.show',
			update: 'api.admin.seminars.update',
			store: 'api.admin.seminars.store',
			destroy: 'api.admin.seminars.destroy',
			buttonDisplay: 'api.admin.seminars.change.button-display'
		}
	}

	@action buttonDisplay = (id: number, form: any, success: () => void, error: () => void) => {
		axios.post(getRouteName(this.route_list.buttonDisplay, id), form)
			.then(({ data }) => {
				this.form.errors = null
				success()
			})
			.catch(({ response }) => {
				this.form.errors = response?.data?.errors ?? null
				error()
			})
	}
}

export default new seminarStore()
