import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme: any) => ({
	row: {
		height: '42px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: theme.spacing(1)
	}
}))
