import { observable, action, autorun } from 'mobx'
import axios from 'axios'
import { getRouteName } from 'share/helper'
class authStore {

	name = 'authStore'

	constructor() {
		autorun(() => {
			this.checkAuth()
		})
	}

	@observable user = null
	@observable pass = false

	@action setUser = (user: any) => {
		this.user = user
	}

	@action handleLogin = (form: { email: string, password: string }) => {
		axios.post(getRouteName('api.admin.auth.token'), form)
			.then(({ data }: any) => {
				this.user = data.user
				localStorage.setItem('token', data.access_token)
				localStorage.setItem('expires_at', data.expires_at)
				this.setToken()
			})
	}

	@action checkAuth = () => {
		const localToken = localStorage.getItem('token')
		const expires = localStorage.getItem('expires_at')
		const expiresFormat = expires ? Date.parse(expires.replace(/\s/, 'T')) : 0

		if (localToken && expiresFormat > Date.now()) {
			this.setToken()
			this.getUserInfo()
		} else {
			this.removeToken()
		}
	}

	@action setToken = () => {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		this.pass = true
	}

	@action removeToken = () => {
		this.pass = false
		axios.defaults.headers.common['Authorization'] = ''
		localStorage.removeItem('token')
		localStorage.removeItem('expires_at')
	}

	@action getUserInfo = () => {
		axios.get(getRouteName('api.admin.users.current'))
			.then(({ data }: any) => {
				this.user = data.data
			})
			.catch(() => {
				this.removeToken()
			})
	}

	@action logout = () => {
		axios.post(getRouteName('api.admin.auth.revoke'))
			.then(() => {
				this.removeToken()
			})
			.catch(() => {
				this.removeToken()
			})
	}
}

export default new authStore()
