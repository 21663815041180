import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1
    },
    logo: {
        color: '#fff',
        textTransform: 'uppercase',
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: '5px',
        transition: 'all 0.3s',
        '&:hover': {
            color: 'red',
        }
    },
    logOut: {
        transition: 'all 0.3s',
        '&:hover': {
            color: 'red',
        }
    }
}))