import React from 'react'
import WebIcon from '@material-ui/icons/Web'

export default [
	{
		id: 'part',
		path: '/part/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'part.list',
		path: '/part',
		sort: 129,
		exact: true,
		menu: true,
		section: 'content',
		icon: () => <WebIcon />,
		name: 'Секции страниц',
		redirect: true,
		to: 'part/1',
	},
	{
		id: 'part.show',
		path: '/part-show/:id',
		component: React.lazy(() => import('./page/show')),
	},
	{
		id: 'part.store',
		path: '/part-store',
		component: React.lazy(() => import('./page/store')),
	},

] as TRoute[]
