import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme: any) => ({
	row: {
		borderRadius: '4px',
		padding: theme.spacing(1),
		display: 'flex',
		flexBasis: 420
	},
	form: {
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	},
	input: {
		flexGrow: 1,
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '-0.05px',
	}
}))
