import { defaultList } from 'share/store/defaultList'
class categoryStore extends defaultList {

	name = 'categoryStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.category.index',
			show: 'api.admin.category.show',
			update: 'api.admin.category.update',
			store: 'api.admin.category.store',
			destroy: 'api.admin.category.destroy'
		}
	}
}

export default new categoryStore()