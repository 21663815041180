import { useState, useEffect } from 'react'
import axios from 'axios'
import { getRouteName } from './getRouteName'

export const useGetRoles = () => {

	const [list, setList] = useState<any[]>([])

	useEffect(() => {
		axios.get(getRouteName('api.admin.roles'))
			.then(({ data }: any) => {
				setList(data?.data ?? [])
			})
	}, [])

	return list
}
