import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { ThemeProvider } from '@material-ui/styles'
import { Routes } from 'core/components'
import { useStore } from 'share/helper'
import { Header, Sidebar, Content, Row } from './components'
import theme from './theme'


const LayoutComponent: React.FC = () => {

	const { authStore } = useStore()

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Row>
					{authStore.pass && <Header />}
					{authStore.pass && <Sidebar />}
					<Content>
						<Routes />
					</Content>
				</Row>
			</ThemeProvider>
		</BrowserRouter>
	)
}

export const Layout = observer(LayoutComponent)
