import { defaultList } from 'share/store/defaultList'

class pageStore extends defaultList {

	name = 'pageStore'

	constructor() {
		super()
		this.route_list = {
			index: 'api.admin.pages.index',
			show: 'api.admin.pages.show',
			update: 'api.admin.pages.update',
			store: 'api.admin.pages.store',
			destroy: 'api.admin.pages.destroy'
		}
	}
}

export default new pageStore()
