import React from 'react'
import FilterNoneIcon from '@material-ui/icons/FilterNone'

export default [
	{
		id: 'filter',
		path: '/filter/:page',
		component: React.lazy(() => import('./page')),
	},
	{
		id: 'filter.list',
		path: '/filter',
		sort: 125,
		exact: true,
		menu: true,
		section: 'catalog',
		icon: () => <FilterNoneIcon />,
		name: 'Фильтры',
		redirect: true,
		to: '/filter/1',
	},
	{
		id: 'filter.store',
		path: '/filter-store',
		component: React.lazy(() => import('./page/store')),
	},
	{
		id: 'filter.show',
		path: '/filter-show/:id',
		component: React.lazy(() => import('./page/show')),
	}
] as TRoute[]
